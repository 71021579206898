@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;700;800&display=swap');

/* final project */
body {
  font-family: 'Inter', sans-serif;
}

.comingsoon-section {
  padding: 20vh 0;
}

.comingsoon-section .comingWrapper {
  text-align: center;
}

.comingsoon-section .comingWrapper h1 {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 53px;
  font-style: normal;
  font-weight: var(--fw-Extra-bold-text);
  line-height: 71px;
  margin: 0px 0 10px;
  text-align: center;
}
.comingsoon-section .comingWrapper p{
  margin-bottom: 20px;
}
.comingsoon-section .comingWrapper .btnWrapper{
  margin: 0 auto;
}

/* ComingSoon End */
/* Thank You page */
.comingsoon-section .comingWrapper .sent-icon{
  width: 100%;
  max-width: 100px;
  margin: 0  auto 20px;
}
.comingsoon-section .comingWrapper .sent-icon img{
  width: 100px;
  rotate: 10deg;
}
.comingsoon-section .comingWrapper .sent-icon.E404 {
  max-width: 200px;
}
.comingsoon-section .comingWrapper .sent-icon.E404 img{
  width: 200px;
  rotate: none;
}
.comingsoon-section .comingWrapper p{
  max-width: 600px;
  margin: 0 auto 20px;
  color: #000;
}
.comingsoon-section .comingWrapper  h3{
  margin-bottom: 10px;
}
/* Thank You page End */
/* Error page Start */
.comingsoon-section.error .btnWrapper{
  margin: 0 auto;
}
/* Error page End */
/* header */
#root .header {
  box-sizing: border-box;
  background-color: var(--Base-White) !important;
  box-shadow: 0px 4px 40px 0px rgba(0, 0, 0, 0.10);
  position: relative;
}

#root .headerWrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  height: 80px;
  background-color: var(--Base-White) !important;
  border: none;
}

#root .headerWrapper .navbar-brand {
  margin: 0;
  padding: 0;
}

#root .headerWrapper .navbar-nav {
  margin: 0 30px 0 auto;
}

#root .headerWrapper .nav-link.nav-list-item,
#root .headerWrapper .navbar-nav #basic-nav-dropdown {
  padding: 0;
  margin: 0 12px;
  text-decoration: none;
  color: var(--Gray-gray-700);
  font-size: var(--fs-medium-Text);
  font-weight: var(--fw-bold-text);
  line-height: 25px;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  box-shadow: none;
  outline: none;
}

#root .headerWrapper .nav-link:not(.nav-item) {
  padding: 0;
}

#root .headerWrapper .navbar-nav .nav-item.show #basic-nav-dropdown,
#root .headerWrapper .nav-link:active {
  color: var(--Brand-Primary-bg);
}

#root .headerWrapper .navbar-nav #basic-nav-dropdown:hover,
#root .headerWrapper .nav-link:hover {
  color: var(--Brand-Primary-bg);
}

#root .headerWrapper .header-btn .navBtn {
  width: 100%;
  max-width: 124px;
  padding: 14px 25px;
  height: 42px;
  color: var(--Base-White);
  background: var(--linearGradient-color);
  font-size: 14px;
}

#root .headerWrapper .header-btn .navBtn a {
  text-decoration: none;
  color: var(--Base-White);
  font-size: 14px;
  font-style: normal;
  font-weight: var(--fw-Semibold-Text);
  line-height: 14px;
}

#root .headerWrapper .header-btn .navBtn:hover {
  background: #57007B;
  box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
}

#root .headerWrapper .hamburger-menu {
  display: none;
}

/* mega-menu CSS */
#root .headerWrapper .navbar-nav .dropdown-menu.show {
  top: 9px;
  padding: 0;
  margin: 0;
}

#root .headerWrapper .navbar-nav .dropdown-header {
  padding: 0;
  margin-bottom: 10px;
}

#root .headerWrapper .navbar-nav .dropdown-header h4 {
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: normal;
  margin: 0;
  padding: 0;
}

#root .headerWrapper .dropdown-item .nav-link.nav-list-item {
  margin: 0 0 10px;
}

#seeMoreLink {
  color: #607d8b;
}

#seeMoreLink:hover {
  color: black;
  font-weight: 400;
}

.dropdown-item:hover {
  outline: none;
  box-shadow: none;
  background: none;
}

.dropdown-item a:hover {
  color: black;
}

.dropdown-item {
  height: unset;
  padding: 0;
}

.dropdown-item .nav-list-item {
  padding: 0 !important;
  margin-bottom: 5px;
}

.dropdown-menu {
  /* position: relative; */
  padding-top: 10px;
}

.nav-item.dropdown {
  position: inherit;
}

.dropdown-menu {
  width: 100%;
  transform: translate(0px, 70px);
  /* change numbers for your layout */
}

.navbar-toggler {
  width: auto;
  padding: 0;
  margin: 0;
  border: 0;
  background-color: #fff;
}

.navbar-toggler:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.navbar-toggler-icon {
  background-image: url('../src/asstes/humburger.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  width: 35px;
  height: 35px;
}

@media only screen and (min-width: 600px) {
  .navbar-collapse {
    justify-content: space-between;
    align-items: center;
  }

  .eventsNav {
    position: absolute;
    left: 0;
    background: #F9F9FF;
    width: 100%;
    padding-bottom: 0;
    border-radius: 0 0 4px 4px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  }

  .dropdown-menu .row {
    justify-content: space-between;
    padding: 20px 5px;
    box-sizing: border-box;
    margin: 0;
  }

  .text-left {
    width: -moz-calc(25% - 20px);
    width: -webkit-calc(25% - 20px);
    width: -o-calc(25% - 20px);
    width: calc(25% - 20px);
    padding: 0;
  }
}

@media (max-width:991.98px) {
  #root .headerWrapper .navbar-nav {
    margin: 0;
  }

  #root .headerWrapper .nav-link.nav-list-item {
    margin: 0 0 15px;
    text-align: left;
  }

  #root .headerWrapper .navbar-nav .dropdown-menu.show {
    border: 0 !important;
  }

  #root .headerWrapper .navbar-nav #basic-nav-dropdown {
    margin: 0;
  }

  #root .headerWrapper .dropdown-item .nav-link.nav-list-item {
    font-size: var(--fs-Small-Text);
    font-weight: var(--fw-light-Text);
  }

  #root .headerWrapper .navbar-nav .dropdown-header h4 {
    font-size: var(--fs-medium-Text);
    font-weight: var(--fw-bold-text);
  }

  .navbar-collapse {
    position: absolute;
    top: 80px;
    width: 100%;
    left: 0;
    padding: 20px;
    z-index: 1;
    background-color: var(--Base-White);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
    border: none;
  }

  .eventsNav {
    background: #F9F9FF;
    position: static !important;
    transform: unset;
    box-shadow: none;
    border: 0 !important;
    outline: none;
    border-radius: 0;
  }

  .dropdown-menu {
    transform: unset;
  }

  #root .headerWrapper .nav-link.nav-list-item.show .eventsNav {
    border: 0 !important;
  }

  #root .headerWrapper .nav-link.nav-list-item.show .eventsNav .container {
    max-width: 100% !important;
    padding: 0;
  }

  #root .headerWrapper .nav-list {
    display: none;
  }

  #root .headerWrapper .logo-wrapper {
    margin-right: auto;
  }

  #root .headerWrapper .header-btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: flex-start;
  }

  #root .headerWrapper .hamburger-menu {
    display: block;
  }

  #root .headerWrapper .mob-nav-list {
    z-index: 99;
    position: absolute;
    top: 80px;
    left: 0;
  }

  #root .headerWrapper .nav-list.mob-nav-list {
    background-color: #fff;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: left;
    width: 100%;
    height: auto;
    padding-left: 20px;
  }

  #root .headerWrapper .nav-list.mob-nav-list a {
    padding: 0;
    margin-bottom: 15px;
  }

  #root .headerWrapper .header-btn.mob-header-btn {
    display: block;
    position: absolute;
    top: 80px;
    left: 0;
    z-index: 99;
  }

}

@media (max-width:767.98px) {
  .dropdown-menu .row {
    flex-direction: column;
    padding: 10px 0;
  }

  .dropdown-menu .row .text-left {
    width: 100%;
  }

  #root .headerWrapper .navbar-nav .dropdown-header h4 {
    font-size: var(--fs-Extra-Large-Text);
    font-weight: var(--fw-Extra-Sbold-Text);
  }

  #root .headerWrapper .dropdown-item .nav-link.nav-list-item {
    font-size: var(--fs-medium-Text);
    font-weight: var(--fw-bold-text);
  }

  .dropdown-menu .row .text-left:last-child .dropdown-divider.d-md-none {
    display: none !important;
  }
}





/* header End */
#root .hero-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
  padding-top: 30px;
}

#root .hero-wrapper::after {
  position: absolute;
  content: '';
  background-image: url('./asstes/bg-up.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  width: 72px;
  height: 72px;
  left: 403px;
  bottom: -111px;
  z-index: -2;
}


#root .hero-wrapper .left-content {
  width: 100%;
  max-width: 556px;
  padding-left: 25px;
}

#root .hero-wrapper .left-content h1.title {
  color: var(--Gray-gray-900);
  font-family: var(--main-font);
  font-size: 51px;
  font-style: normal;
  font-weight: var(--fw-Extra-bold-text);
  line-height: 71px;
  margin: 0 0 10px 0;
  padding: 0;
}

#root .hero-wrapper .left-content h1.title small {
  display: block;
  font-size: 30px;
  line-height: 33px;
  font-weight: var(--fw-light-Text);
}

#root .hero-wrapper .left-content h1.title small span,
#root .hero-wrapper .left-content h1.title span b {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#root .hero-wrapper .left-content h1.title span {
  background: none;
  color: var(--Base-Black);
  background-clip: unset;
  -webkit-background-clip: unset;
  -webkit-text-fill-color: unset;
}

#root .hero-wrapper .left-content p {
  padding: 0;
  margin: 0 0 30px 0;
}

#root .hero-wrapper .left-content a.btn {
  max-width: 175px;
  height: 52px;
  padding: 19px;
  color: var(--Gray-gray-50);
}

#root .hero-wrapper .left-content a {
  text-decoration: none;
  color: var(--Base-White);
}

/* #root .hero-wrapper .left-content button:hover {
  border-radius: 5px;
  background: var(--primary-hover-color);
  box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
} */

#root .hero-wrapper .right-content {
  width: 100%;
  max-width: -moz-calc(100% - 556px);
  max-width: -webkit-calc(100% - 556px);
  max-width: -o-calc(100% - 556px);
  max-width: calc(100% - 556px);
}

#root .hero-wrapper .right-content img {
  width: 100%;
  height: auto;
}


@media (max-width:991.98px) {
  #root .hero-section .hero-wrapper {
    flex-direction: column;
  }

  #root .hero-wrapper::after {
    bottom: -90px;
  }
  #root .hero-wrapper .left-content{
    text-align: center;
    padding-left: 0;
    margin-bottom: 30px;
  }
  #root .hero-section.e-comm .hero-wrapper .right-content img{
    max-width: 400px !important;
  }
  /* #root .hero-wrapper .left-content {
    padding: 0;
    max-width: 380px;
    margin: 0 auto 30px;
  } */

  #root .hero-wrapper .right-content {
    max-width: 100%;
  }
}

@media (max-width:767.98px) {
  #root .hero-wrapper::after {
    display: none;
  }
  #root .hero-wrapper .left-content p{
    margin-bottom: 15px;
  }
}

/* hero-section End */
#root .service-section {
  background-color: #F9F9FF;
  padding: 46px 0 94px !important;
  box-sizing: border-box;
  overflow-x: hidden;
}

#root .service-wrapper>span {
  display: block;
  font-family: var(--main-font);
  color: var(--Brand-Primary-bg);
  text-align: center;
  font-size: var(--fs-EX-Small-Text);
  font-style: normal;
  font-weight: var(--fw-bold-text);
  line-height: 30px;
  margin-bottom: 5px;
}

#root .service-wrapper h2 {
  width: 100%;
  font-family: var(--main-font);
  max-width: 354px;
  margin: 0 auto 41px;
  text-align: center;
  align-items: center;
}

#root .service-wrapper h2::before {
  display: none;
}

#root .service-wrapper .main-slider .slick-arrow {
  display: none !important;
}

#root .service-wrapper .main-slider .slick-dots {
  bottom: -41px;
}

#root .service-wrapper .main-slider .slick-dots li {
  width: 13px;
  height: 13px;
  margin: 0 5px;
  font-size: 0;
}

#root .service-wrapper .main-slider .slick-dots li button {
  width: 13px;
  height: 13px;
  background: var(--Base-White);
  border: 1px solid #CBD5E0;
  border-radius: 50%;
  font-size: 0;
}

#root .service-wrapper .main-slider .slick-dots li.slick-active button {
  width: 15px;
  height: 15px;
  font-size: 0;
  border: 1px solid transparent;
  background: rgb(247, 102, 128);
  background: -moz-linear-gradient(225deg, rgba(247, 102, 128, 1) 0%, rgba(87, 0, 123, 1) 100%);
  background: -webkit-linear-gradient(225deg, rgba(247, 102, 128, 1) 0%, rgba(87, 0, 123, 1) 100%);
  background: linear-gradient(225deg, rgba(247, 102, 128, 1) 0%, rgba(87, 0, 123, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f76680", endColorstr="#57007b", GradientType=1);
  border-radius: 50%;
}

#root .service-wrapper .main-slider .slick-dots li button:before {
  display: none;
}

#root .service-wrapper .main-slider .slick-list {
  overflow: visible;
  padding: 0 !important;
}

/* #root .service-wrapper .main-slider .slick-list .slick-track {
  margin: 0 -20px;
} */

#root .service-wrapper .main-slider .slick-slide {
  padding: 0 20px;
}

#root .service-wrapper .main-slider .slick-slide>div {
  height: 100%;
  width: 100%;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  /* margin: 0 20px; */
  margin-bottom: 39px;
  margin-top: 0;
}

#root .service-wrapper .main-slider .slick-slide.slick-current>div {
  margin-top: 39px;
  margin-bottom: 0;
  border-radius: 7px;
  border: 1px solid #F76680;
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.10);
}

#root .service-wrapper .main-data {
  height: 100%;
  position: relative;
}

#root .service-wrapper .main-data .main-details {
  /* display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  height: 100%;
  border-radius: 7px;
  background: var(--Gray-gray-50);
  box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
  padding: 36px 16px;
  box-sizing: border-box; */
  margin: 0 auto;
}

#root .service-wrapper .main-data .img-wraper {
  margin-bottom: 20px;
}

#root .service-wrapper .main-data .img-wraper .imgWrap {
  width: 58px;
  height: 58px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 27px;
  border: 1px solid #000;
  border-radius: 50%;
  margin-bottom: 15px;
}

#root .service-wrapper .main-data .img-wraper .imgWrap img {
  display: block;
  width: 36px;
  height: auto;
}

#root .service-wrapper .main-slider .slick-slide span {
  color: var(--Gray-gray-800);
  font-family: var(--main-font);
  font-size: var(--fs-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Semibold-Text);
  line-height: 27.305px;
}

#root .service-wrapper .main-slider .slick-slide.slick-current span {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#root .service-wrapper .main-data .data-content {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  margin-top: auto;
}

#root .service-wrapper .main-data .data-content p {
  text-align: left;
}

#root .service-wrapper .main-slider .slick-slide.slick-current p {
  color: var(--Gray-gray-700);
}

@media (max-width:991.98px) {
  #root .service-section .cContainer {
    max-width: 100% !important;
  }

  /* #root .service-wrapper .main-data {
    height: auto;
  }

  #root .service-wrapper .main-slider .slick-list {
    margin: 0 -10px;
  }

  #root .service-wrapper .main-slider .slick-slide>div {
    margin: 0 10px;
  } */
}

@media (max-width:767px) {
  #root .service-wrapper .main-slider .slick-slide.slick-current>div {
    margin-top: 0;
  }
}

/* service-section End */

#root .company-section {
  padding: 182px 0 75px;
  background-color: #FFF;
  overflow: hidden;
}

#root .company-section .comapny-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  position: relative;
}

#root .company-section .comapny-wrapper::before {
  position: absolute;
  content: '';
  background-image: url('./asstes/bg-up.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  width: 72px;
  height: 72px;
  left: 25px;
  top: -217px;
  z-index: 1;
}

#root .company-section .comapny-wrapper .left-details {
  width: 100%;
  max-width: 560px;
  padding-top: 28px;
  padding-left: 25px;
  position: relative;
}

#root .company-section .comapny-wrapper .section-title.left::before {
  top: 4px;
}

#root .company-section .comapny-wrapper .left-details h2 {
  margin: 0 0 30px 0;
}

#root .company-section .comapny-wrapper .left-details h2 span {
  font-weight: var(--fw-Extra-Sbold-Text);
}

#root .company-section .comapny-wrapper .left-details p {
  margin: 0;
  padding: 0;
  max-width: 530px;
  margin-bottom: 25px;
}

#root .company-section .comapny-wrapper .left-details p span {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#root .company-section .comapny-wrapper .left-details a.information-Link {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  text-decoration: none;
}

#root .company-section .comapny-wrapper .left-details a.information-Link span {
  color: var(--Brand-Primary-bg);
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-bold-text);
  line-height: 25px;
  margin-right: 15px;
}

#root .company-section .comapny-wrapper .video-content {
  width: 100%;
  max-width: -moz-calc(100% - 656px);
  max-width: -webkit-calc(100% - 656px);
  max-width: -o-calc(100% - 656px);
  max-width: calc(100% - 656px);
}

@media (max-width:991.98px) {
  #root .company-section {
    padding: 75px 0 50px;
  }

  #root .company-section .comapny-wrapper::before {
    top: -116px;
  }
  #root .company-section .comapny-wrapper {
    flex-direction: column;
  }

  #root .company-section .comapny-wrapper .left-details h2 {
    margin-bottom: 20px;
    text-align: center;
  }

  #root .company-section .comapny-wrapper .left-details {
    padding-left: 0;
    max-width: 100%;
    margin-bottom: 30px;
    align-items: center;
  }

  #root .company-section .comapny-wrapper .left-details p {
    margin-bottom: 25px;
    max-width: 100%;
  }

  #root .company-section .comapny-wrapper .video-content {
    max-width: 100%;
  }

  #root .company-section .comapny-wrapper .video-content img {
    width: 100%;
  }
}

@media (max-width:767.98px) {
  #root .company-section .comapny-wrapper .left-details h2{
    font-size: 24px;
  }
  #root .company-section .comapny-wrapper .left-details{
    padding-top: 0;
  }
  #root .company-section{
    padding: 50px 0;
  }
  #root .company-section .comapny-wrapper::before {
    display: none;
  }
}

/* Company-section End */

#root .technology-section {
  /* padding: 88px 0 62px; */
  overflow: hidden;
}


#root .technology-section .title {
  width: 100%;
  max-width: 412px;
  margin: 0 0 72px;
  position: relative;
  padding-left: 25px;
  padding-top: 42px;
}

#root .technology-section .section-title::before {
  top: 16px;
}

#root .technology-section .technical-idea {
  padding: 60px 0 62px;
  background: #F7F7FA;
  position: relative;
}

#root .technology-section .technical-idea::before {
  position: absolute;
  content: '';
  background-image: url('./asstes/bg-up.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  width: 72px;
  height: 72px;
  right: 553px;
  top: -39px;
  z-index: -2;
}

#root .technical-idea .main-img .slick-list {
  overflow: visible;
  margin: 0 -20px;
  padding: 0 !important;
}

#root .technical-idea .main-img .slick-slide>div {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0 20px;
}

#root .technical-idea .main-img .slick-dots li button,
#root .technical-idea .main-img .slick-arrow {
  display: none;
}

/* technology-section  End*/

#root .review-section .mainTitle {
  width: 100%;
  max-width: 474px;
  margin: 0 auto 63px;
  position: relative;
  padding-top: 26px;
}

#root .review-section .reviewContent {
  width: 100%;
  max-width: 535px;
  margin: 0 auto 53px;
  position: relative;
}

#root .review-section .reviewContent p {
  text-align: center;
}

#root .review-section .reviewStar {
  margin-bottom: 63px;
}

#root .review-section .reviewContent::before {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: url('./asstes/left-comma.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  left: -18%;
  top: -14px;
}

#root .review-section .reviewContent::after {
  content: '';
  position: absolute;
  width: 40px;
  height: 40px;
  background-image: url('./asstes/right-comma.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  right: -18%;
  bottom: 70px;
}

#root .review-section .data-slider .slick-arrow,
#root .review-section .data-slider .slick-arrow::before {
  display: none !important;
}

#root .review-section .review-slider {
  width: 100%;
  max-width: 978px;
  margin: 0 auto;
}

#root .review-section .review-slider .slick-list {
  overflow: visible;
  margin: 0 -38px;
  padding: 0 !important;
}

#root .review-section .review-slider .slick-dots li button:before {
  font-size: 10px;
}

#root .review-section .review-slider .slick-dots li.slick-active button:before {
  color: #57007B;
}

#root .review-section .review-slider .slick-slide>div {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
  margin: 0 38px;
}

#root .review-section .review-slider .slick-slide>div {
  opacity: 0.6;
}

#root .review-section .review-slider .slick-slide.slick-current>div {
  opacity: 1;
}

#root .review-section .review-slider .imgWrap {
  text-align: center;
}

#root .review-section .review-slider .imgWrap img {
  margin: 0 auto 15px;
}

#root .review-section .review-slider .imgWrap span {
  display: block;
  margin-bottom: 5px;
  color: var(--Brand-Primary-bg);
  text-align: center;
  font-family: var(--main-font);
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: normal;
  letter-spacing: -0.09px;
  margin: 0;
  padding: 0;
}

#root .review-section .review-slider .imgWrap p {
  color: #000;
  text-align: center;
}

#root .review-section .review-slider .slick-arrow {
  background-image: url(./asstes/Btn-left.jpg);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 45px;
  height: 45px;
}

#root .review-section .review-slider .slick-arrow:hover {
  opacity: 0.6;
}

#root .review-section .review-slider .slick-arrow.slick-prev {
  top: -144px;
  left: -151px;
}

#root .review-section .review-slider .slick-arrow.slick-next {
  background-image: url(./asstes/Btn-right.jpg);
  width: 45px;
  height: 45px;
  right: -151px;
  top: -144px;
}


#root .review-section .review-slider .slick-arrow::before {
  display: none !important;
}

@media (max-width:1300px) {
  #root .review-section .review-slider .slick-arrow.slick-prev {
    left: -85px;
  }

  #root .review-section .review-slider .slick-arrow.slick-next {
    right: -85px;
  }
}

@media (max-width:1199px) {
  #root .review-section .review-slider .slick-arrow.slick-prev {
    left: 0px;
  }

  #root .review-section .review-slider .slick-arrow.slick-next {
    right: 0px;
  }
}

@media (max-width:991.98px) {
  #root .review-section .review-slider .slick-arrow {
    top: -206px !important;
  }

  #root .review-section .reviewContent::before,
  #root .review-section .reviewContent::after {
    display: none;
  }
}

@media (max-width:767px) {
  #root .review-section .mainTitle {
    margin-bottom: 40px;
  }

  #root .review-section .reviewContent {
    max-width: 340px;
    margin-bottom: 40px;
  }
}

@media (max-width:665px) {
  #root .review-section .review-slider {
    padding-bottom: 30px;
  }
}

/* review-section End */

#root .caseStudy-section {
  background: #F7F7FA;
  padding: 75px 0 114px;
}

#root .caseStudy-section .caseStudy-wrapper {
  position: relative;
}

#root .caseStudy-section .caseStudy-wrapper::after {
  position: absolute;
  content: '';
  background-image: url('./asstes/arrow-img-down.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 204px;
  height: 282px;
  top: -196px;
  right: 97px;
}

#root .caseStudy-section .caseStudy-wrapper::before {
  position: absolute;
  content: '';
  background-image: url('./asstes/arrow-img-up.svg');
  background-repeat: no-repeat;
  background-size: cover;
  width: 144px;
  height: 209px;
  transform: rotate(1.384deg);
  top: -203px;
  left: 16px;
}

#root .caseStudy-section .caseStudy-title {
  width: 100%;
  max-width: 474px;
  margin: 0 auto 81px;
  padding-top: 38px;
  position: relative;
}

#root .caseStudy-section .section-title::before {
  top: 16px;
}

#root .caseStudy-section .caseStudy-title::after {
  position: absolute;
  content: '';
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
  -webkit-text-fill-color: transparent;
  left: -106px;
  top: 3px;
  display: none;
}

#root .caseStudy-section .caseStudy-data {
  width: 100%;
  max-width: 1233px;
  margin: 0 auto 0;
}

#root .caseStudy-section .main-data {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  margin-bottom: 33px;
  border-radius: 10px 30px 30px 10px;
  border: 1px solid #E7DAED;
}
#root .caseStudy-section .main-data:last-child{
  margin-bottom: 0;
}

#root .caseStudy-section .main-data .img-wraper {
  width: 100%;
  max-width: 601px;
}

#root .caseStudy-section .main-data .img-wraper .imgWrap img {
  border-radius: 10px 30px 30px 10px;
}

#root .caseStudy-section .main-data .data-content {
  width: 100%;
  max-width: 723px;
  padding: 40px 51px;
  border-radius: 10px 30px 30px 10px;
}

#root .caseStudy-section .main-data:first-child {
  background: #F1F2FF;
}

#root .caseStudy-section .main-data:nth-child(2) {
  background: #F0FFF7;
}

#root .caseStudy-section .main-data:last-child {
  background: #FFF4F4;
}

#root .caseStudy-section .main-data .data-content .data-wrap {
  width: 100%;
  max-width: 529px;
  margin: 0 auto;
}

#root .caseStudy-section .main-data .data-wrap h3 {
  width: 100%;
  max-width: 451px;
  /* color: var(--Gray-gray-800);
  font-family: var(--main-font);
  font-size: var(--fs-Over-Text);
  font-style: normal;
  font-weight: var(--fw-Semibold-Text);
  line-height: 136.023%; */
  margin: 0 0 30px;
}

#root .caseStudy-section .main-data .data-wrap p {
  margin: 0 0 60px;
}

#root .caseStudy-section .main-data .data-wrap a.readmoreLink {
  width: 100%;
  max-width: 100px;
  margin-left: auto;
}

#root .caseStudy-section .main-data .data-wrap a.readmoreLink span {
  border-bottom: 1px solid transparent;
}

#root .caseStudy-section .main-data .data-wrap a.readmoreLink:hover span {
  border-bottom: 1px solid var(--Brand-Primary-bg);
}
@media (max-width:1085px) {
  #root .caseStudy-section .main-data .data-content{
    padding: 25px 20px;
  }
}
@media (max-width:991.98px) {
  #root .caseStudy-section .caseStudy-title {
    margin-bottom: 50px;
  }

  #root .caseStudy-section .main-data {
    flex-direction: column;
    margin-bottom: 45px;
    border-radius: 10px 30px 30px 10px;
  }
  #root .caseStudy-section .main-data:last-child{
    margin-bottom: 0;
  }
  #root .caseStudy-section .main-data .data-wrap p{
    margin-bottom: 40px;
  }

  #root .caseStudy-section .main-data .img-wraper{
    max-width: 100%;
    /* border-radius: 30px; */
    border-radius: 10px 30px 30px 10px;
  }
  #root .caseStudy-section .main-data .data-content .data-wrap {
    max-width: 100%;
  }

  #root .caseStudy-section .main-data .img-wraper img {
    width: 100%;
    /* border-radius: 30px; */
    border-radius: 10px 30px 30px 10px;
  }

  #root .caseStudy-section .caseStudy-wrapper::after {
    right: 0;
  }

  #root .caseStudy-section .main-data .data-content {
    max-width: 100%;
    padding: 40px;
    border-radius: 30px;
  }
}

@media (max-width:767px) {
  #root .caseStudy-section {
    padding: 50px 0;
  }
  #root .caseStudy-section .main-data{
    margin-bottom: 35px;
  }
  #root .caseStudy-section .main-data .data-content{
    padding: 25px;
  }

  #root .caseStudy-section .main-data,
  #root .caseStudy-section .main-data .img-wraper .imgWrap img,
  #root .caseStudy-section .main-data .img-wraper img {
    border-radius: 10px;
  }

  #root .caseStudy-section .caseStudy-wrapper::after,
  #root .caseStudy-section .caseStudy-wrapper::before {
    display: none;
  }
}

/* caseStudy-section End */

#root .build-section .title {
  margin-bottom: 70px;
  position: relative;
}

#root .build-section .title h2 {
  padding-top: 30px;
}

#root .build-section .title .section-title::before {
  top: 4px;
}

#root .build-section .build-data .dataWrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 95px;
}

#root .build-section .build-data .dataWrapper:last-child {
  margin-bottom: 0;
}

#root .build-section .build-data .dataWrapper:nth-child(2) {
  flex-flow: row-reverse;
}

#root .build-section .build-data .dataWrapper:nth-child(2) .leftBuild {
  padding-left: 74px;
  padding-right: 0px;
}

#root .build-section .build-data .dataWrapper.about:nth-child(2) .leftBuild {
  padding-right: 23px;
}

#root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild {
  padding-left: 22px;
  display: flex;
  justify-content: center;
}
#root .build-section .build-data .dataWrapper .leftBuild {
  width: 50%;
  max-width: 630px;
  padding-top: 9px;
  padding-left: 23px;
}

#root .build-section .build-data .dataWrapper h3 {
  margin: 0 0 30px;
  padding: 0;
}
#root .build-section .build-data .dataWrapper .details .para {
  margin: 0 0 16px;
  max-width: 536px;
}

#root .build-section .build-data .dataWrapper .Review {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
}

#root .build-section .build-data .dataWrapper .Review li {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  list-style: none;
  position: relative;
  padding-left: 27px;
  /* 166.667% */
}

#root .build-section .build-data .dataWrapper .Review li::before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--linearGradient-color);
  top: 12px;
  left: 11px;
}

/* List-style-1 */
#root .build-section .build-data .dataWrapper:not(:last-child) .list-style-1 {
  display: none;
}

#root .build-section .build-data .dataWrapper.about:last-child {
  align-items: flex-start;
}

#root .build-section .build-data .dataWrapper.about:last-child .leftBuild {
  padding-top: 22px;
}

#root .build-section .build-data .dataWrapper .list-style-1 li {
  position: relative;
  list-style: none;
  padding-left: 30px;
  max-width: 555px;
  margin-bottom: 0px;
}

#root .build-section .build-data .dataWrapper .list-style-1 li::before {
  position: absolute;
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background: var(--linearGradient-color);
  top: 12px;
  left: 11px;
}

#root .build-section .build-data .dataWrapper .list-style-1 li p span {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: var(--fs-Extra-Large-Text);
}

#root .build-section .build-data .dataWrapper .rightBuild {
  width: 50%;
  max-width: -moz-calc(50% - 40px);
  max-width: -webkit-calc(50% - 40px);
  max-width: -o-calc(50% - 40px);
  max-width: calc(50% - 40px);
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

#root .build-section .build-data .dataWrapper .rightBuild img {
  border-radius: 10px;
  height:auto;
}


#root .build-section .build-data .dataWrapper .rightBuild::after {
  position: absolute;
  content: '';
  width: 65px;
  height: 65px;
  left: -25px;
  top: -21px;
  background-image: url('./asstes/round-yellow.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
}

#root .build-section .build-data .dataWrapper .rightBuild::before {
  position: absolute;
  content: '';
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
  -webkit-text-fill-color: transparent;
  left: 45%;
  bottom: -15px;
  transform: translateX(-65%);
  z-index: -1;
}

#root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild::after {
  left: 109px;
  top: inherit;
  bottom: -40px;
  width: 74px;
  height: 74px;
}

#root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild::before {
  left: auto;
  right: -22px;
  top: -11px;
  width: 28px;
  height: 28px;
}

#root .build-section .build-data .dataWrapper.about:nth-child(2) .rightBuild::before {
  right: -20px;
  top: -11px;
}

#root .build-section .build-data .dataWrapper:last-child .rightBuild::after {
  left: -16px;
  bottom: -13px;
  top: inherit;
  width: 43px;
  height: 43px;
}

#root .build-section .build-data .dataWrapper:last-child .rightBuild::before {
  left: 52%;
  top: -20px;
  bottom: 0;
  transform: translateX(-50%);
  width: 41px;
  height: 41px;
}

@media (max-width:1310px) {
  
  #root .build-section .build-data .dataWrapper .rightBuild img {
    width: auto;
  }

  #root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild::before {
    right: -26px;
  }
}

@media (max-width:991.98px) {
  #root .build-section .title {
    margin-bottom: 30px;
  }

  #root .build-section .build-data .dataWrapper {
    flex-direction: column;
    margin-bottom: 40px;
  }

  #root .build-section .build-data .dataWrapper h3 {
    text-align: center;
  }

  #root .build-section .build-data .dataWrapper .details .para {
    max-width: 100%;
  }

  #root .build-section .build-data .dataWrapper:nth-child(2) {
    flex-flow: column !important;
  }
  #root .build-section .build-data .dataWrapper.cms:nth-child(even){
    flex-flow: column !important;
  }
  #root .build-section .build-data .dataWrapper .leftBuild {
    width: 100%;
    max-width: 100%;
    padding-left: 0;
    margin-bottom: 30px;
  }

  #root .build-section .build-data .dataWrapper:nth-child(2) .leftBuild {
    padding: 0;
  }


  #root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild {
    padding-left: 0;
  }

  #root .build-section .build-data .dataWrapper .rightBuild {
    width: 100%;
    max-width: 100%;
  }

  #root .build-section .build-data .dataWrapper .rightBuild img {
    width: auto;
    max-width: 100%;
    border-radius: 16px;
  }

  #root .build-section .build-data .dataWrapper:nth-child(2) .rightBuild::before {
    right: -26px;
  }

  #root .build-section .build-data .dataWrapper .list-style-1 li {
    max-width: 100%;
  }
}

@media (max-width:767px) {
  #root .build-section .build-data .dataWrapper h3 {
  font-size: 24px;
  }

}

/* .build-section End */

#root .development-section {
  background: #F7F7FA;
}

#root .development-section .devTitle {
  position: relative;
  margin-bottom: 80px;
}

#root .development-section .section-title {
  padding-top: 20px;
}

#root .development-section .section-title::before {
  top: -4px;
}

#root .development-section .development-data {
  width: 100%;
  max-width: 1233px;
  margin: 0 auto;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  /* align-items: center; */
  justify-content: space-between;
}

#root .development-section .development-data .data-wrap {
  width: 50%;
  max-width: -moz-calc(50% - 12px);
  max-width: -webkit-calc(50% - 12px);
  max-width: -o-calc(50% - 12px);
  max-width: calc(50% - 12px);
  background: var(--Gray-gray-50);
  margin-bottom: 22px;
  box-shadow: 5px 10px 15px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

#root .development-section .development-data .development-details {
  width: 100%;
  height: 100%;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 57px 33px 29px 34px;
}

#root .development-section .development-data .development-details .data-img {
  margin-right: 25px;
}

#root .development-section .development-data .data-img img {
  width: 59px;
}

#root .development-section .development-data .details h4 {
  /* color: var(--Gray-gray-900);
  font-family: var(--main-font);
  font-size: var(--fs-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Semibold-Text); */
  line-height: 27.305px;
  margin: 0 0 10px;
  padding: 0;
}


@media (max-width:1265px) {
  /* #root .development-section .development-data .data-wrap {
    max-width: calc(50% - 60px);
    padding: 57px 25px 29px 24px;
  } */
}

@media (max-width:991.98px) {
  #root .development-section .devTitle{
    margin-bottom: 40px;
  }
  #root .development-section .development-data {
    max-width: 100%;
    flex-direction: column;
  }

  #root .development-section .development-data .data-wrap {
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
  }

  #root .development-section .development-data .development-details {
    padding: 40px;
  }
}

@media (max-width:767px) {
  #root .development-section .devTitle{
    margin-bottom: 30px;
  }
  #root .development-section .development-data .development-details {
    padding: 20px;
  }
}

@media (max-width:430px) {
  #root .development-section .development-data .development-details {
    flex-direction: column;
  }

  #root .development-section .development-data .development-details .data-img {
    margin: 0 auto 25px;
  }

  #root .development-section .development-data .details h4 {
    text-align: center;
  }
}

/* .development-section End */
#root .tech-section.cSection {
  padding-bottom: 0;
}

#root .tech-section .techTitle {
  position: relative;
}

#root .tech-section .section-title {
  padding-top: 29px;
  margin-bottom: 37px;
}

#root .tech-section .section-title::before {
  top: 4px;
}

#root .tech-section .techTitle .techLink {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto 60px;
  padding: 0;
}

#root .tech-section .techTitle .techLink button {
  text-decoration: none;
  font-family: var(--main-font);
  color: var(--Gray-gray-900);
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-light-Text);
  line-height: 30px;
  padding: 0;
  background-color: var(--Base-White);
  width: -moz-calc(20% - 20px);
  width: -webkit-calc(20% - 20px);
  width: -o-calc(20% - 20px);
  width: calc(20% - 20px);
  border: 0;
  outline: 0;
}
#root .tech-section .techTitle .techLink button:hover,
#root .tech-section .techTitle .techLink button.active {
  position: relative;
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#root .tech-section .techTitle .techLink button.active::before {
  position: absolute;
  content: '';
  width: 69px;
  height: 5px;
  background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
  bottom: -4px;
  left: 50%;
  transform: translateX(-50%);
}

#root .tech-section .tabImg {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  max-width: 1000px;
  margin: 0 auto;
}

#root .tech-section .tabImg .TechLogo {
  width: -moz-calc(20% - 20px);
  width: -webkit-calc(20% - 20px);
  width: -o-calc(20% - 20px);
  width: calc(20% - 20px);
  margin: 0 0 30px 0;
}

#root .tech-section .tabImg .TechLogo img {
  display: block;
  width: 100px;
  margin: 0 auto;
}

@media (max-width:991.98px) {
  #root .tech-section .techTitle .techLink button {
    font-size: var(--fs-medium-Text);
  }
  #root .tech-section .tabImg .TechLogo{
    width: -moz-calc(30% - 20px);
  width: -webkit-calc(30% - 20px);
  width: -o-calc(30% - 20px);
  width: calc(30% - 20px);
  }
}

@media (max-width:767px) {
  #root .tech-section .techTitle .techLink {
    max-width: -moz-calc(100% - 20px);
    max-width: -webkit-calc(100% - 20px);
    max-width: -o-calc(100% - 20px);
    max-width: calc(100% - 20px);
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-bottom: 20px;
  }

  #root .tech-section .tabImg .TechLogo {
    width: -moz-calc(50% - 20px);
    width: -webkit-calc(50% - 20px);
    width: -o-calc(50% - 20px);
    width: calc(50% - 20px);
  }

  #root .tech-section .techTitle .techLink button {
    width: 100%;
    max-width: 200px;
    margin: 0 30px;
  }
  #root .tech-section .techTitle .techLink button.active::before{
    bottom: 0;
  }
}

/* .tech-section End */

#root .work-section .workTitle {
  position: relative;
  margin-bottom: 40px;
}

#root .work-section .section-title {
  padding-top: 59px;
}

#root .work-section .section-title::before {
  top: 34px;
}

#root .work-section .workDetails {
  width: 100%;
  max-width: 1230px;
  margin: 0 auto;
  position: relative;
}

#root .work-section .workDetails.tab {
  display: none;
}

#root .work-section .workDetails .midLine {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 0;
}

#root .work-section .workDetails .midLine span {
  display: block;
  width: -moz-calc(100% - 48px);
  width: -webkit-calc(100% - 48px);
  width: -o-calc(100% - 48px);
  width: calc(100% - 48px);
  height: 2px;
  background: rgb(247, 102, 128);
  background: -moz-linear-gradient(90deg, rgba(247, 102, 128, 0) 0%, rgba(247, 102, 128, 1) 15%, rgba(247, 102, 128, 1) 100%);
  background: -webkit-linear-gradient(90deg, rgba(247, 102, 128, 0) 0%, rgba(247, 102, 128, 1) 15%, rgba(247, 102, 128, 1) 100%);
  background: linear-gradient(90deg, rgba(247, 102, 128, 0) 0%, rgba(247, 102, 128, 1) 15%, rgba(247, 102, 128, 1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#f76680", endColorstr="#f76680", GradientType=1);
  /* background-color: #F76680; */
}

#root .work-section .workContent {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

#root .work-section .workContent.workContent1 {
  justify-content: flex-start;
}

#root .work-section .workContent.workContent2 {
  justify-content: flex-end;
  padding-right: 30px;
}

#root .work-section .workDetails.tab .workContent {
  justify-content: space-between;
  flex-flow: row wrap;
  padding: 0;
}

#root .work-section .workContent .workdata {
  width: 100%;
  max-width: 299px;
  border-radius: 9px;
  border: 1px solid #E7DAED;
  background: var(--Base-White);
  padding: 22px 33px 19px 21px;
  position: relative;
  margin: 0 38px;
}

#root .work-section .workDetails.tab .workdata {
  width: 100%;
  max-width: -moz-calc(33.33% - 20px);
  max-width: -webkit-calc(33.33% - 20px);
  max-width: -o-calc(33.33% - 20px);
  max-width: calc(33.33% - 20px);
  margin: 0 auto 20px;
  padding: 20px;
}

#root .work-section .workContent .workdata::after {
  position: absolute;
  content: '';
  width: 2px;
  height: 30px;
  background-color: #F76680;
  left: 50%;
  bottom: -45px;
  transform: translateX(-50%);
}

#root .work-section .workContent.workContent2 .workdata::after {
  bottom: unset;
  top: -45px;
}

#root .work-section .workDetails.tab .workdata::after {
  display: none;
}

#root .work-section .workContent .workdata h5 {
  color: var(--Gray-gray-900);
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: normal;
  letter-spacing: -0.09px;
  margin: 0 0 15px;
  padding: 0;
  white-space: nowrap;
}

#root .work-section .workContent .workdata h5 span {
  display: inline-block;
  margin-right: 10px;
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

#root .work-section .workContent .workdata p {
  color: var(--Gray-gray-700);
  line-height: 20px;
  letter-spacing: -0.07px;
  margin: 0;
  padding: 0;
}

@media (max-width:1080px) {
  #root .work-section .workDetails:not(.tab) {
    display: none;
  }

  #root .work-section .workDetails.tab {
    display: block;
  }
}

@media (max-width:991.98px) {
  #root .work-section .workDetails.tab .workdata {
    max-width: -moz-calc(50% - 20px);
    max-width: -webkit-calc(50% - 20px);
    max-width: -o-calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width:670px) {
  #root .work-section .workDetails.tab .workdata {
    max-width: 100%;
    flex-direction: column;
  }

  #root .work-section .workContent .workdata h5 {
    white-space: inherit;
  }
}

/* .work-section End */

#root .lead-section .lead-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1230px;
  margin: 0 auto;
  padding: 88px 84px;
  border-radius: 20px;
  background: linear-gradient(225deg, #F1F1F5 0%, #E4ECF7 100%);
}
#root .lead-section.cSection {
  padding-top: 0;
}

#root .lead-section.about.cSection {
  padding-top: 25px;
}

/* #root .lead-section.about .lead-wrapper {
  padding: 60px 84px;
} */
#root .lead-section .lead-wrapper .lead-btn{
  justify-content: center;
}

#root .lead-section .lead-wrapper .lead-data {
  width: 100%;
  max-width: 510px;
}

#root .lead-section.about .lead-data {
  max-width: 720px;
}

/* #root .lead-section .lead-wrapper .lead-data h2 {
  margin: 0;
  padding: 0;
  font-size: var(--fs-over-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: 55px;
  background: linear-gradient(225deg, #29272E 0%, #27272E 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
} */

/* #root .lead-section .lead-wrapper .lead-btn {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 262px;
  position: relative;
  z-index: 2;
}

#root .lead-section .lead-wrapper .cbtn-1 {
  width: 100%;
  max-width: 100%;
}

#root .lead-section .lead-wrapper .cbtn-1 button {
  display: block;
  width: 100%;
  height: 57px;
  padding: 14px 25px;
  border-radius: 5px;
  border: 1px solid #FFF;
  color: var(--Gray-gray-50);
  font-family: var(--main-font);
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: normal;
  letter-spacing: -0.09px;
  background: linear-gradient(225deg, #FFC656 0%, #F16063 100%);
  outline: none;
  cursor: pointer;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

#root .lead-section .lead-wrapper .cbtn-1 button:hover {
  background: linear-gradient(90deg, #FFC656 0%, #F16063 100%);
}

#root .lead-section .lead-wrapper .lead-btn svg {
  position: absolute;
  right: 71px;
  z-index: -1;
} */

@media (max-width:991.98px) {
  #root .lead-section .lead-wrapper {
    padding: 80px 60px;
  }
  #root .lead-section .lead-wrapper h2{
    font-size: var(--fs-Over-Text);
    line-height: 136.023%;
  }
}

@media (max-width:750px) {
  #root .lead-section .lead-wrapper {
    flex-direction: column;
    padding: 40px;
  }

  #root .lead-section .lead-wrapper .lead-data h2 {
    font-size: var(--fs-Over-Text);
    font-weight: var(--fw-Semibold-Text);
    line-height: 136.023%;
    text-align: center;
    margin-bottom: 20px;
  }

  #root .lead-section .lead-wrapper .lead-btn svg {
    display: none;
  }

  #root .lead-section .lead-wrapper .cbtn-1 button {
    font-size: 16px;
    padding: 14px 20px;
  }
}

/* .lead-section End */

/* ABOUT PAGE SECTION CSS */

#root .detail-section {
  background-color: var(--Base-White);
}

#root .detail-section .detailWrapper {
  max-width: 1077px;
  margin: 0 auto;
  position: relative;
}

#root .detail-section .detailWrapper::before {
  position: absolute;
  content: '';
  background-image: url('./asstes/bg-up.jpg');
  background-repeat: no-repeat;
  background-position: left;
  background-size: cover;
  width: 72px;
  height: 72px;
  left: 74px;
  bottom: -113px;
  z-index: -1;
}

#root .detail-section .detailWrapper h1 {
  width: 100%;
  /* max-width: 491px; */
  margin: 0 auto 20px;
  padding: 0;
  text-align: center;
}
#root .detail-section .detailWrapper h1 span {
  font-size: 45px;
  font-style: normal;
  font-weight: 800;
  line-height: 71px;
}
#root .detail-section h1 small {
  color: var(--Gray-gray-900);
  text-align: center;
  font-size: 45px;
  font-style: normal;
  font-weight: 300;
  line-height: 71px;
  /* 157.778% */
}

#root .detail-section h1 span {
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 53px;
  font-style: normal;
  font-weight: 800;
  line-height: 71px;
}

#root .detail-section p {
  padding: 0;
  margin: 0;
  text-align: center;
}

@media (max-width:1076px) {

  #root .detail-section .detailWrapper h1 span {
    font-size: 40px;
    line-height: 66px;
  }

  #root .detail-section h1 small {
    font-size: 40px;
    line-height: 66px;
  }
}
@media (max-width:991.98px) {
  #root .detail-section .detailWrapper::before {
    bottom: -90px;
  }
}
@media (max-width:767px) {
  #root .detail-section .detailWrapper::before {
    bottom: -70px;
  }
  #root .detail-section .detailWrapper h1 span {
    font-size: 35px;
    line-height: 50px;
  }

  #root .detail-section h1 small {
    font-size: 35px;
    line-height: 50px;
  }
}

@media (max-width:430px) {
  #root .detail-section .detailWrapper h1 span {
    font-size: 28px;
    line-height: 40px;
  }

  #root .detail-section h1 small {
    font-size: 28px;
    line-height: 40px;
  }
}

/*  END detail-section */

/*  START shopify page  plan-section */

#root .hero-section.e-comm .hero-wrapper::after {
  display: none;
}

#root .hero-section.e-comm .left-content h2,
#root .hero-section.e-comm .left-content h3 {
  margin-bottom: 10px;
}

#root .hero-section.e-comm .hero-wrapper .right-content img {
  max-width: 500px;
  margin: 0 auto;
}
#root  .plan-section {
  background-color: #F9F9FF;
}
#root .plan-section .main-table {
  width: 100%;
  text-align: center;
  border-collapse: collapse;
  margin-bottom: 50px;
  background-color: #fff;
}

#root .plan-section .main-table.price-table .PriceTitle {
  width: 40%;
}

#root .plan-section .main-table.price-table .table-inner-text:not(.PriceTitle) {
  width: 20%;
}

#root .plan-section .main-table thead tr th,
#root .plan-section .main-table tbody tr td {
  border: 1px solid #E7DAED;
}

#root .plan-section .main-table thead tr th:first-child,
#root .plan-section .main-table tbody tr td:first-child {
  width: 45%;
}

#root .plan-section .main-table thead th {
  border-bottom: 1px solid #ddd !important;
  border-right: 1px solid transparent !important;
}

#root .plan-section .main-table .table-heading {
  color: var(--Gray-gray-800);
  font-family: var(--main-font);
  font-size: var(--fs-Extra-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Semibold-Text);
  line-height: 27.305px;
  padding: 20px 15px;

  text-transform: uppercase
}

#root .plan-section .main-table .table-heading.odd-bg {
  background-color: #57007B;
  color: #E7DAED;
}

#root .plan-section .main-table .table-heading.even-bg {
  background-color: #E7DAED;

}

#root .plan-section .main-table tbody tr td.table-inner-text {
  color: var(--Gray-gray-800);
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-Semibold-Text);
  line-height: 27.305px;
  padding: 18px 15px;
}

#root .plan-section .main-table tbody tr td.table-inner-text a {
  text-decoration: none;
}

#root .plan-section .main-table tbody tr td.table-inner-text a:hover {
  text-decoration: revert;
}

#root .plan-section .plan-wrapper .price-btn {
  margin-bottom: 50px;
}

#root .plan-section .plan-wrapper .price-btn ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  max-width: 400px;
  justify-content: space-between;
  margin: 0 auto;
}

#root .plan-section .plan-wrapper .price-btn ul li {
  list-style: none;
  width: 100%;
  max-width: 175px;
}

#root .plan-section .plan-wrapper .price-btn ul li .cBtn {
  height: 52px;

}

#root .plan-section .shopify-area {
  width: 100%;
  max-width: 445px;
  margin: 0 auto;
}

#root .plan-section .shopify-area .shopify-text {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}

#root .plan-section .shopify-area .shopify-text span {
  display: block;
  background: var(--linearGradient-color);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-bold-text);
  line-height: 25px;
}

#root .plan-section .shopify-area .shopify-text a {
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-bold-text);
  line-height: 25px;
  margin-left: 20px;
  color: #57007B !important;
  text-decoration: none;
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}

#root .plan-section .shopify-area .shopify-text a:hover {
  text-decoration: underline;
}

 @media (max-width:767px) {
  #root .plan-section.cSection .cContainer {
  padding-right: 0;
  }
  #root .plan-section  .plan-wrapper{
    overflow-x: scroll;
    padding-right: 15px;
  }
  #root .plan-section table.price-table.main-table.NRI td.table-inner-text {
    white-space: nowrap;
  }
 }
 @media (max-width:700px) {
  #root .plan-section .main-table,
  #root .plan-section .plan-wrapper .price-btn{
    width: 720px;
  }
  #root .plan-section .plan-wrapper .price-btn ul li:first-child{
    margin-right: 20px;
  }
  #root .plan-section .plan-wrapper .price-btn ul{
    margin: 0;
    max-width: 100%;
    justify-content: center;
  }
 }
 @media (max-width:500px) {
  #root .plan-section .shopify-area,
  #root .plan-section .shopify-area .shopify-text a{
    margin: 0;
  }
  #root .plan-section .shopify-area .shopify-text{
    flex-direction: column;
    margin-bottom: 0;
  }
  #root .plan-section .shopify-area .shopify-text:first-child{
    margin-bottom: 20px;
  }
  #root .plan-section .shopify-area .shopify-text a{
    font-size: var(--fs-Small-Text);
  }
 }
/*  END shopify page  plan-section */
/* END  ABOUT PAGE SECTION CSS */
/* Start  hire PAGE SECTION CSS */
#root .hero-section.hire .hero-wrapper{
   justify-content: center;
}
#root .hero-section.hire .left-content{
  width: -moz-calc(100% - 556px);
  width: -webkit-calc(100% - 556px);
  width: -o-calc(100% - 556px);
  width: calc(100% - 556px);
  padding-left: 75px;
}
#root .hero-section.hire .right-content{
  width: 100%;
  max-width: 400px;
}
@media (max-width:991.98px) {
  #root .hero-section.hire .left-content{
    width: 100%;
    padding: 0;
    text-align: center;
    margin-bottom: 30px;
  }
  #root .hero-section.hire .right-content{
    max-width: 500px;
  }
}
/* End  hire PAGE SECTION CSS *
/* Start  contact PAGE SECTION CSS */
#root .contact {
  padding: 40px 0;
}

#root .contact .hero-wrapper .left-content {
  max-width: 550px;
  padding-left: 40px;
}

#root .contact .hero-wrapper::after {
  bottom: -90px;
}

#root .contact .hero-wrapper .left-content .contact-title,
#root .contact .contact-data {
  margin-bottom: 30px;
}

#root .contact .contact-data ul {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  text-align: center;
  justify-content: space-between;
  width: 100%;
}

#root .contact .contact-data ul .cont-details {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: flex-start;
  width: -moz-calc(50% - 10px);
  width: -webkit-calc(50% - 10px);
  width: -o-calc(50% - 10px);
  width: calc(50% - 10px);
  margin-bottom: 10px;
}

#root .contact .contact-data ul .cont-details .cont-img {
  width: 100%;
  max-width: 24px;
  margin-right: 20px;
}

#root .contact .contact-data ul .cont-details .cont-data {
  text-align: start;
}

#root .contact .contact-data ul .cont-details .cont-data span {
  display: block;
  font-size: var(--fs-Extra-Large-Text);
  font-weight: var(--fw-Semibold-Text);
}

#root .contact .contact-data ul .cont-details .cont-data p {
  padding: 0;
  margin: 0;
  font-size: var(--fs-Small-Text);
}
#root .contact .hero-wrapper .right-content{
  max-width: 500px;
}

@media (max-width:991.98px) {
  #root .contact .hero-wrapper .left-content{
    padding: 0;
    max-width: 100%;
    margin-bottom: 30px;
  }
  #root .contact .hero-wrapper .cBtn {
    display: block;
   margin: 0 auto;
  }
  /* #root .contact .left-content .btnWrapper{
    margin: 0 auto;
  }  */
}
@media (max-width:575px) {
  #root .contact .hero-wrapper .left-content{
    margin-bottom: 30px;
  }
  #root .contact .hero-wrapper .left-content,
  #root .contact .hero-wrapper .rigth-content{
    max-width: 300px;
  }
  
  #root .contact .contact-data ul{
    flex-direction: column;
    margin-bottom: 10px;
  }
  #root .contact .contact-data ul .cont-details{
   width: 100%;
  }
  #root .contact .hero-wrapper .right-content img{
    max-width: 300px !important;
    margin: 0 auto;
  }
  /* #root .contact .left-content .btnWrapper{
    margin: 0 0 auto;
  }  */
}

/* END  contact PAGE SECTION CSS */
/* START  FORM  SECTION CSS */
#root .form-section {
  padding: 75px 0;
  background-color: #F9F9FF;
}

#root .form-section .form-wrapper {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
}

#root .form-section .form-wrapper h3 {
  text-align: center;
  margin-bottom: 30px;
  color: var(--Brand-Primary-bg);
}

#root .form-section .form-wrapper form {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

#root .form-section .form-wrapper form .field-wrapper {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  flex-direction: column;
  width: -moz-calc(50% - 10px);
  width: -webkit-calc(50% - 10px);
  width: -o-calc(50% - 10px);
  width: calc(50% - 10px);
  margin-bottom: 20px;
  position: relative;
}

#root .form-section .form-wrapper form .field-wrapper.full-width {
  width: 100%;
  margin-bottom: 30px;
}

#root .form-section .form-wrapper form .field-wrapper label {
  margin: 0;
  padding: 0 4px;
  box-sizing: border-box;
  position: absolute;
  top: 16px;
  left: 14px;
  z-index: 3;
  color: #333;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  /* 142.857% */
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
  pointer-events: none;
  white-space: nowrap;
  max-width: -moz-calc(100% - 28px);
  max-width: -webkit-calc(100% - 28px);
  max-width: -o-calc(100% - 28px);
  max-width: calc(100% - 28px);
  overflow: hidden;
  text-overflow: ellipsis;
  opacity: 0;
}

#root .form-section .form-wrapper form .field-wrapper.full-width label {
  color: #8D8D8D;
  opacity: 1;
}

#root .form-section .form-wrapper form .field-wrapper.focus label,
#root .form-section .form-wrapper form .field-wrapper.filled label {
  color: #000 !important;
  font-family: "Manrope", sans-serif;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 14px;
  /* 140% */
  top: -7px;
  left: 14px;
  opacity: 1;
  max-width: -moz-calc(100% - 28px);
  max-width: -webkit-calc(100% - 28px);
  max-width: -o-calc(100% - 28px);
  max-width: calc(100% - 28px);
}

#root .form-section .form-wrapper form .field-wrapper.error label {
  color: #ff0000 !important;
}

#root .form-section .form-wrapper form .field-wrapper.error .error {
  position: absolute;
  left: auto;
  right: 0;
  bottom: -17px;
  color: #ff0000 !important;
  font-family: "Manrope", sans-serif;
  font-size: 11px;
  font-style: normal;
  font-weight: 400;
}

#root .form-section .form-wrapper form .field-wrapper input,
#root .form-section .form-wrapper form .field-wrapper textarea {
  color: #333;
  background-color: #F9F9FF !important;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  outline: none;
  border: 0;
  border-bottom: 1px solid #8D8D8D;
  background: #F9F9FF;
  padding: 13px 16px;
  margin: 0;
  height: auto;
  display: block;
  position: relative;
  outline: none !important;

}
#root .form-section .form-wrapper form .field-wrapper input{
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
#root .form-section .form-wrapper form .field-wrapper input[type="file"]{
  padding: 10px 16px !important;
}
#root .form-section .form-wrapper form .field-wrapper ::-webkit-input-placeholder {
  color: #8D8D8D;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;

}

#root .form-section .form-wrapper form .field-wrapper ::-moz-placeholder {
  /* Firefox 19+ */
  color: #8D8D8D;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

#root .form-section .form-wrapper form .field-wrapper :-ms-input-placeholder {
  /* IE 10+ */
  color: #8D8D8D;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

#root .form-section .form-wrapper form .field-wrapper :-moz-placeholder {
  /* Firefox 18- */
  color: #8D8D8D;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

#root .form-section .form-wrapper form .field-wrapper ::placeholder {
  color: #8D8D8D;
  font-family: "Manrope", sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
}

/* focus */
#root .form-section .form-wrapper form .field-wrapper.focus ::-webkit-input-placeholder {
  opacity: 0;
}

#root .form-section .form-wrapper form .field-wrapper.focus ::-moz-placeholder {
  /* Firefox 19+ */
  opacity: 0;
}

#root .form-section .form-wrapper form .field-wrapper.focus :-ms-input-placeholder {
  /* IE 10+ */
  opacity: 0;
}

#root .form-section .form-wrapper form .field-wrapper.focus :-moz-placeholder {
  /* Firefox 18- */
  opacity: 0;
}

#root .form-section .form-wrapper form .field-wrapper.focus ::placeholder {
  opacity: 0;
}


#root .form-section .form-wrapper form .field-wrapper.filled .error {
  display: none;
}


input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: #000;
  -webkit-box-shadow: 0 0 0px 1000px #F9F9FF inset;
  transition: background-color 5000s ease-in-out 0s;
}

#root .form-section .form-wrapper form button {
  width: 100%;
  max-width: 150px;
  margin-left: auto;
  height: 48px;
  padding: 10px;
}
@media (max-width:991.98px) {
  #root .form-section{
    padding: 50px 0;
  }
}
@media (max-width:575px) {
  #root .form-section .form-wrapper form .field-wrapper{
    width: 100%;
  }
  #root .form-section .form-wrapper form button{
    margin: 0 auto;
  }
}
/* END  FORM SECTION CSS */

/* START  CMS PAGE  CSS */

#root .hero-section.cms .hero-wrapper::after{
  display: none;
}
#root .hero-section.cms .hero-wrapper .left-content{
  max-width: 500px;
 padding: 0;
}
#root .hero-section.cms .hero-wrapper .left-content h2{
  margin-bottom: 10px;
  }
#root .hero-section.cms .hero-wrapper .left-content h3{
  margin-bottom: 20px;
}
#root .hero-section.cms .hero-wrapper .left-content p{
  margin-bottom: 50px;
}
/* #root .hero-section.cms .hero-wrapper .left-content .cms-logo{
  margin-bottom: 40px;
} */
#root .hero-section.cms .hero-wrapper .left-content .cms-logo ul{
  width: 100%;
  max-width: 500px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}
#root .hero-section.cms .hero-wrapper .left-content .cms-logo ul li{
  /* width: -moz-calc(33.33% - 25px);
  width: -webkit-calc(33.33% - 25px);
  width: -o-calc(33.33% - 25px);
  width: calc(33.33% - 25px); */
  height: auto;
  list-style: none;
}
#root .hero-section.cms .hero-wrapper .left-content .cms-logo ul li img{
  display: block;
  width: 50px;
 height: auto;
 pointer-events: none;
}
#root .hero-section.cms .hero-wrapper .right-content{
  width: calc(100% - 600px);
  max-width: 100%;
}
#root .hero-section.cms .hero-wrapper .right-content img{
  width: 500px;
  margin: 0 auto;
}
@media (max-width: 991.98px) {
  #root .hero-section.cms .hero-wrapper .right-content{
    width: 100%;
    max-width: 100%;
  }
  
}
@media (max-width: 475px) {
  #root .hero-section.cms .hero-wrapper .left-content .cms-logo ul{
    justify-content: center;
  }
  #root .hero-section.cms .hero-wrapper .left-content .cms-logo ul li{
    width: -moz-calc(25% - 10px);
    width: -webkit-calc(25% - 10px);
    width: -o-calc(25% - 10px);
    width: calc(25% - 10px);
    margin-bottom: 20px;
  }
  #root .hero-section.cms .hero-wrapper .left-content .cms-logo ul li img{
    margin: 0 auto;
  }
  
}
/* basicWeb page css */
#root .build-section.basicWeb  .build-data .dataWrapper:not(:last-child) .list-style-1,
#root .build-section.cmsWeb  .build-data .dataWrapper:not(:last-child) .list-style-1{
  display: block;
}
#root .build-section.basicWeb .build-data .dataWrapper .list-style-1 li{
  margin-bottom: 5px;
}
#root .build-section.basicWeb .build-data .dataWrapper .rightBuild::after,
#root .build-section.basicWeb .build-data .dataWrapper .rightBuild::before,
#root .build-section.cmsWeb .build-data .dataWrapper .rightBuild::after,
#root .build-section.cmsWeb .build-data .dataWrapper .rightBuild::before{
  display: none;
}

#root .build-section.basicWeb .build-data .dataWrapper .rightBuild img{
  max-width: 100%;
  height:315px;
  object-fit: cover;
}
#root .build-section.basicWeb .build-data .dataWrapper.about:last-child{
  align-items: center;
}
#root .build-section .build-data .dataWrapper:nth-child(3) .rightBuild img{
  max-width: 100%;
  margin: 0 auto;
}
#root .build-section .build-data .dataWrapper:nth-child(2) li:nth-child(5),
#root .build-section .build-data .dataWrapper:nth-child(2) li:nth-child(6),
#root .build-section .build-data .dataWrapper:nth-child(2) li:nth-child(7),
#root .build-section .build-data .dataWrapper:nth-child(2) li:nth-child(8),
#root .build-section .build-data .dataWrapper:nth-child(3) li:nth-child(6),
#root .build-section .build-data .dataWrapper:nth-child(3) li:nth-child(7),
#root .build-section .build-data .dataWrapper:nth-child(3) li:nth-child(8){
display: none;
}
#root .lead-section.basicWeb  .lead-wrapper .lead-data{
  max-width: -moz-calc(100% - 430px);
  max-width: -webkit-calc(100% - 430px);
  max-width: -o-calc(100% - 430px);
  max-width: calc(100% - 430px);
}
#root .build-section .build-data .dataWrapper.cms:nth-child(even){
  flex-direction: row-reverse;
}
@media (max-width:991.98px) {
  #root .lead-section.basicWeb .lead-wrapper{
    flex-direction: column;
  }
  #root .lead-section.basicWeb .lead-wrapper .lead-btn svg{
    display: none;
  }
  #root .lead-section.basicWeb  .lead-wrapper .lead-data{
    max-width: 100%;
    margin-bottom: 30px;
    text-align: center;
  }
}
@media  screen and (max-width:767px) {
  #root .build-section.basicWeb .build-data .dataWrapper .rightBuild img{
    width: auto;
    height: auto;
  }
}
/* END  basicWeb page css */
/* END  CMS PAGE CSS */

/* START Design Page css */
#root .build-section .build-data .dataWrapper.design:nth-child(4) {
  flex-flow:  row-reverse;
}
@media screen and (max-width: 991.98px) {
  
  #root .build-section .build-data .dataWrapper.design:nth-child(4){
    display: block;
  }
}

/* END Design Page css */


/* START CRO Configuration & Setup */
#root .build-section .what-is-cro{
  text-align: center;
  margin-bottom: 40px;
}
#root .build-section .what-is-cro .cro-title{
  margin: 0;
  padding-bottom: 10px;
  font-size: var(--fs-over-Large-Text);
  font-style: normal;
  font-weight: var(--fw-Extra-Sbold-Text);
  line-height: 55px;
  color: var(--Brand-Primary-bg);
}
#root .build-section .what-is-cro .cro-text{
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 20px;
    color: #000;
}
#root .build-section .build-data .dataWrapper.cro:nth-child(4),.dataWrapper.cro:nth-child(6) {
  flex-flow:  row-reverse;
}
@media screen and (max-width: 991.98px) {
  
  #root .build-section .build-data .dataWrapper.cro:nth-child(4){
    display: block;
  }
}
/* END CRO Configuration & Setup */



/* START  footer SECTION CSS */
#root footer.cSection {
  /* padding: 59px 0 0 !important; */
  padding: 0 !important;
  background-color: #F7F7FA;
}

#root footer .cContainer {
  max-width: 1257px;
}

#root footer .footer-data {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  display: none;
}

#root footer .footer-data .footerLogo {
  width: -moz-calc(50% - 25px);
  width: -webkit-calc(50% - 25px);
  width: -o-calc(50% - 25px);
  width: calc(50% - 25px);
}

#root footer .footer-data .footerLogo .logo-wrapper {
  margin-bottom: 6px;
}

#root footer .footer-data p.para {
  margin: 0;
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-light-Text);
  line-height: 25px;
  color: var(--Gray-gray-600);
}

#root footer .footer-data .footer-content {
  width: -moz-calc(50% - 25px);
  width: -webkit-calc(50% - 25px);
  width: -o-calc(50% - 25px);
  width: calc(50% - 25px);
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

#root footer .footer-data .footer-content .footerData,
#root footer .footer-data .social-link {
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
}

#root footer .footer-data .social-link {
  justify-content: flex-end;
}

#root footer .footer-data h5 {
  color: var(--Gray-gray-700);
  margin-bottom: 19px;
}

#root footer .footer-data .footerLinks ul {
  padding: 0;
  margin: 0;
}

#root footer .footer-data .footerLinks ul li {
  list-style: none;
  margin-bottom: 5px;
}

#root footer .footer-data .footerLinks ul li a {
  text-decoration: none;
  color: var(--Gray-gray-600);
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-light-Text);
  line-height: 25px;
}

#root footer .footer-data .footerContact {
  width: 50%;
}

#root footer .footer-data .footerContact .contactDetail {
  margin-bottom: 35px;
}

#root footer .footer-data .footerContact .contactDetail p {
  text-decoration: none;
  color: var(--Gray-gray-600);
  font-family: var(--main-font);
  font-size: var(--fs-medium-Text);
  font-style: normal;
  font-weight: var(--fw-light-Text);
  line-height: 22.683px;
  margin: 0 0 10px;
  padding: 0;
}

#root footer .footer-data .socialLink {
  margin-top: auto;
}

#root footer .footer-data .socialLink ul {
  padding: 0;
  margin: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

#root footer .footer-data .socialLink ul li {
  list-style: none;
}

#root footer .footer-data .socialLink ul li a:hover svg {
  filter: grayscale(100%) sepia(100%);
}

#root footer .footer-detail {
  padding: 30px 0;
  box-sizing: border-box;
  /* border-top: 1px solid#CBD5E0; */
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

#root footer .footer-detail span {
  display: block;
  color: var(--Gray-gray-700);
  font-family: var(--main-font);
  font-size: var(--fs-Small-Text);
  font-style: normal;
  font-weight: var(--fw-light-Text);
  line-height: 20px;
  letter-spacing: -0.07px;
  padding: 0;
  margin: 0;
  text-align: center;
  align-items: center;
}
#root footer .footer-detail .socialLink ul {
  padding: 0;
  margin: 0;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

#root footer .footer-detail .socialLink ul li {
  list-style: none;
  margin: 0 20px;
}
#root footer .footer-detail .socialLink ul li a,
#root footer .footer-detail .socialLink ul li a img {
  -webkit-transition: all 0.2s;
  -o-transition: all 0.2s;
  transition: all 0.2s;
}
#root footer .footer-detail .socialLink ul li a:hover img {
  transform: scale(1.2);
}


@media (max-width:991.98px) {
  #root footer .footer-data {
    flex-direction: column;
  }
 
  #root footer .footer-data .footerLogo {
    width: 100%;
    margin-bottom: 30px;
  }

  #root footer .footer-data .footer-content {
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
  }

}

@media (max-width:767px) {
  #root footer .footer-detail{
    flex-direction: column;
    padding: 30px 0 20px;
  }
  #root footer .footer-detail span {
    margin-bottom: 20px;
  }
  #root footer .footer-data .footerLogo {
    width: 100%;
    margin-bottom: 40px;
  }

  #root footer .footer-data .footerLinks {
   width: -moz-calc(50% - 20px);
 width: -webkit-calc(50% - 20px);
 width: -o-calc(50% - 20px);
 width: calc(50% - 20px);
  }

  #root footer .footer-data .footer-content {
    width: 100%;
    flex-direction: column;
  }

  /* #root footer .footer-data .footerLinks,
  #root footer .footer-data .footerContact {
    margin: 0 auto;
  } */

  #root footer .footer-data .socialLink ul {
    justify-content: center;
  }

}

@media (max-width:520px) {
  #root footer.cSection {
    text-align: center;
  }

  #root footer .footer-data .footerLogo {
    margin-bottom: 20px;
  }

  #root footer .footer-data .footer-content .footerData {
    margin: 0 0 20px;
    flex-direction: column;
  }

  #root footer .footer-data .footer-content .footerData .footerLinks {
    margin-bottom: 20px;
  }

  #root footer .footer-data .footerContact {
    width: 100%;
  }

  #root footer .footer-data .social-link {
    justify-content: center;
  }
}