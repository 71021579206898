* {
    box-sizing: border-box;
}
html,
body{
    min-height: 100vh;
}
.cSection {
    padding: 75px 0;
}

img {
    margin: 0;
    display: block;
}

ul {
    margin: 0;
    padding: 0;
}
.mainContent{
    height: 100%;
    flex: 1 1 auto;
}
#root { 
    /* overflow: hidden; */
    height: 100%;
}
.pgWrapper{
    display: flex;
    flex-direction: column;
    height: auto;
    height: 100%;
    min-height: 100vh;
    overflow-x: hidden;
}
#root .cContainer,
#root .header .container {
    width: 100%;
    max-width: 1310px !important;
    padding: 0 15px;
    margin: 0 auto;
    box-sizing: border-box;
}

.btn {
    display: inline-block;
    align-items: center;
    text-align: center;
    width: 100%;
    font-size: var(--fs-medium-Text);
    font-style: normal;
    font-weight: var(--fw-Semibold-Text);
    line-height: 13px;
    box-sizing: border-box;
    border-radius: 5px;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    border: none;
    outline: none;
    cursor: pointer;
}

.btn.cBtn {
    color: var(--Gray-gray-50);
    background: #57007B;
    box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);

}

.btn.cBtn:hover {
    border-radius: 5px;
    background: var(--primary-hover-color);
    box-shadow: 0px 4px 49px 0px rgba(0, 0, 0, 0.15);
}

.section-title {
    color: var(--Gray-gray-900);
    text-align: center;
    font-family: var(--main-font);
    font-size: var(--fs-over-Large-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 55px;
    margin: 0;
    padding: 0;
}

.section-title::before {
    position: absolute;
    content: '';
    width: 69px;
    height: 5px;
    background: linear-gradient(225deg, #F76680 0%, #57007B 100%);
    top: 1px;
    left: 50%;
    transform: translateX(-50%);
}

.section-title.left::before {
    left: 60px;
}

.section-title span {
    display: block;
    font-weight: var(--fw-Extra-Sbold-Text);
}

.section-title.left {
    text-align: left;
}

h2 {
    margin: 0;
    padding: 0;
    font-size: var(--fs-over-Large-Text);
    font-style: normal;
    font-weight: var(--fw-Extra-Sbold-Text);
    line-height: 55px;
    color: var(--Brand-Primary-bg);
}

h3 {
    margin: 0;
    color: var(--Gray-gray-900);
    font-family: var(--main-font);
    font-size: var(--fs-Over-Text);
    font-style: normal;
    font-weight: var(--fw-Semibold-Text);
    line-height: 136.023%;
}

h4 {
    margin: 0;
    color: var(--Gray-gray-900);
    font-family: var(--main-font);
    font-size: var(--fs-Large-Text);
    font-style: normal;
    font-weight: var(--fw-Semibold-Text);
}

h5 {
    margin: 0;
    color: var(--Gray-gray-900);
    font-size: var(--fs-Extra-Large-Text);
    font-style: normal;
    font-weight: var(--fw-Extra-Sbold-Text);
    line-height: normal;
    letter-spacing: -0.09px;
}

p {
    margin: 0;
    padding: 0;
    color: var(--Gray-gray-600);
    font-family: var(--main-font);
    font-size: var(--fs-Extra-Large-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 36px;
}

p.text-1 {
    color: var(--Gray-gray-700);
    font-family: var(--main-font);
    font-size: var(--fs-Extra-Large-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 36px;
    /* 200% */
}

p.subtext {
    color: var(--Gray-gray-700);
    font-family: var(--main-font);
    font-size: var(--fs-Small-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 22.683px;
}

p.para {
    color: var(--Gray-gray-800);
    font-family: var(--main-font);
    font-size: var(--fs-Extra-Large-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 30px;
}

p.text {
    color: var(--Gray-gray-800);
    font-family: var(--main-font);
    font-size: var(--fs-medium-Text);
    font-style: normal;
    font-weight: var(--fw-light-Text);
    line-height: 30px;
}

a.readmoreLink {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    text-decoration: none;
}

.readmoreLink span {
    font-family: var(--main-font);
    font-size: var(--fs-Small-Text);
    font-style: normal;
    font-weight: var(--fw-Semibold-Text);
    line-height: 14px;
    background: var(--linearGradient-color);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-right: 5px;
}

.main-details {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-radius: 7px;
    background: var(--Gray-gray-50);
    box-shadow: 0px 4px 30px 0px rgba(0, 0, 0, 0.05);
    padding: 36px 16px;
    box-sizing: border-box;
}

.lead-btn {
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 262px;
    position: relative;
    z-index: 2;
}

.cbtn-1 {
    width: 100%;
    max-width: 100%;
}

.cbtn-1 {
    display: block;
    width: 100%;
    height: 57px;
    padding: 14px 25px;
    border-radius: 5px;
    align-items: center;
    text-align: center;
    color: var(--Gray-gray-50);
    font-family: var(--main-font);
    font-size: var(--fs-Extra-Large-Text);
    font-style: normal;
    font-weight: var(--fw-Extra-Sbold-Text);
    line-height: 30px;
    letter-spacing: -0.09px;
    background: linear-gradient(225deg, #FFC656 0%, #F16063 100%);
    outline: none;
    cursor: pointer;
    border: none;
    -webkit-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    text-decoration: none;
}

.cbtn-1:hover {
    /* background: linear-gradient(225deg, #F16063 0%, #FFC656 100%); */
    background: linear-gradient(90deg, #FFC656 0%, #F16063 100%);
}

.lead-btn svg {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    z-index: -1;
}
.btnWrapper{
    max-width: 175px;
}
.btnWrapper a{
    max-width: 175px;
    height: 52px;
    margin: 0 auto;
    padding: 20px;
    box-sizing: border-box;
  }
@media (max-width:1199px) {}

@media (max-width:991px) {
    .cSection {
        padding: 50px 0;
    }

    #root .cContainer,
    #root .header .container {
        width: 100%;
        max-width: 768px !important;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .section-title {
        font-size: var(--fs-Over-Text);
        line-height: 136.023%;
    }

    .section-title.left::before {
        left: 50%;
        transform: translate(-50%);
    }
}

@media (max-width:767px) {
    .cSection {
        padding: 40px 0;
    }
    h2{
        font-size: var(--fs-Over-Text);
    }
    p.text-1{
        font-size: var(--fs-medium-Text);
    }
    #root .cContainer,
    #root .header .container {
        width: 100%;
        max-width: 100% !important;
        padding: 0 15px;
        margin: 0 auto;
        box-sizing: border-box;
    }

    .section-title.left::before {
        display: none;
    }
    .cbtn-1{
    font-size: var(--fs-medium-Text);
    line-height: 28px;
    }
}